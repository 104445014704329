<template>
   <div class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>消息管理</el-breadcrumb-item>
            <el-breadcrumb-item>商户通知</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 查询框 -->
      <el-row class="content-box">
         <div class="right-tabbox-newnotice">
            <!--表格类型-->
            <!--            type="border-card"-->
            <el-tabs v-model="activeName" @tab-click="getEventNotify">
               <el-tab-pane v-for="(item,index) in tabslist" :key="index" :label="item.label" :name="item.name">

                  <el-row class="tips-box">
                    <p>使用须知：</p>
                     <p>1、使用微信公众号模板消息需先绑定认证服务号，并于微信公众号后台开通“模板消息”功能；</p>
                     <p>2、接收商户通知的微信账户需关注酒店公众号。</p>
                     <p>3、公众号请开启“IT科技-互联网|电子商务”服务类目，小程序请开启“酒店服务”、“休闲娱乐”、“餐厅排队”等类目以便正常使用，消息对应的行业类型见预览；</p>
                     <p>4、每个认证服务号或小程序最多可同时启用25个模板ID（相同的模板编号为一个ID；公众号或小程序已添加的模板一旦达到25个，可能无法正常推送消息，此时请商家进入微信公众号后台删除部分不启用的模板</p>
                  </el-row>
                  <el-row class="statistics-box">
                     <div>
                        <p>已开启公众号模板</p>
                        <p>{{ OfficiallettempletNum }}</p>
                     </div>
                     <div v-if="activeName == 'CLIENT' ">
                        <p>已开启小程序消息</p>
                        <p>{{ applettempletNum }}</p>
                     </div>
                  </el-row>

                  <!-- 表格 -->

                  <el-row class="table-box">

                     <el-table
                           ref="mainTable"
                           border
                           :data="tableData"
                           :stripe="true"
                     >
                        <!-- 推广-->
                           <el-table-column label="消息类型" key="1"  prop="messageType"  width="120">
                           </el-table-column>

                           <el-table-column prop="distributorInformation" key="2"  style="padding: 0" width="190" label="发送规则" :show-overflow-tooltip="true">
                              <template slot-scope="scope" >
                                 <div class="thisrule"  >
                                    <span v-for="(item,index) in scope.row.thisRule" :key="index">{{item}}</span>
                                 </div>
                              </template>

                           </el-table-column>
                           <el-table-column prop="customerInformation" key="3" width="150" label="模板标题"  :show-overflow-tooltip="true">
                              <template slot-scope="scope" >
                                 <div class="thisrule"  >
                                    <span v-for="(item,index) in scope.row.templateTitle" :key="index">{{item}}</span>
                                 </div>
                              </template>
                           </el-table-column>

                        <template v-if="activeName == 'CLIENT' ">
                           <el-table-column prop="orderNo"  label="公众号模板消息" key="4" min-width="110"  >
                              <template slot-scope="scope" >
                                 <div class="thisrule"  >
                                    <span v-for="(item,index) in scope.row.templateMessage" :key="index">{{item}}</span>
                                 </div>
                              </template>
                           </el-table-column>
                           <el-table-column prop="createTime"  label="小程序订阅消息" key="5"  min-width="100">
                              <template slot-scope="scope" >
                              <div class="thisrule" >
                                 <p v-for="(item,index) in scope.row.openingFunction" :key="index" >
                                       <el-switch active-color="#13ce66" active-value="YES" inactive-value="NO"
                                                  v-model='scope.row.openingFunction[index]'
                                                  @change="updateeventNotify(scope.row.openingFunction[index],scope.row.id[index],'isApplet')"></el-switch>
                                       <el-button type="text" @click="lookat(scope.row.templateTitle[index])" style="margin-left: 15px">预览</el-button>
                                 </p>
                              </div>
                              </template>
                           </el-table-column>
                        </template>
                        <!--商户-->
                        <template v-if="activeName == 'COMMERCIAL_TENANT' ">
                           <el-table-column prop="memberDTO.nickName" key="4"  label="公众号模板消息" width="150">
                              <template slot-scope="scope" >
                                 <div class="thisrule" >
                                    <p v-for="(item,index) in scope.row.officialtemplateMessage" :key="index" >
                                       <el-switch active-color="#13ce66" active-value="YES" inactive-value="NO"
                                                  v-model='scope.row.officialtemplateMessage[index]'
                                                  @change="updateeventNotify(scope.row.officialtemplateMessage[index],scope.row.id[index],'isOfficial')"></el-switch>
                                       <el-button type="text" style="margin-left: 15px" @click="lookat(scope.row.thisRule[index])">预览</el-button>
                                    </p>
                                 </div>
                              </template>
                           </el-table-column>
                           <el-table-column prop="customerNum" key="5"  label="公众号消息接收人" min-width="250" >
                              <template slot-scope="scope" >
                                 <div class="thisrule" >
                                    <span v-for="(item,index) in scope.row.recipient" :key="index">
                                    <label v-for="(items,i) in item" :key="i"> {{items.userName || '无'}}</label>
                                       <label class="magbtn" @click="managerecipient(scope.row.recipient[index],scope.row.id[index],'PUBLICACCOUNT',scope.row.code[index])">管 理</label>
                                    </span>
                                 </div>
                              </template>
                           </el-table-column>
                           <el-table-column prop="customerNum" key="6"  label="后台消息通知" width="130" >
                              <template slot-scope="scope" >
                                 <div class="thisrule" >
                                    <span v-for="(item,index) in scope.row.messageFunction" :key="index" >
                                       <el-switch active-color="#13ce66" active-value="YES" inactive-value="NO"
                                                  v-model='scope.row.messageFunction[index]'
                                                  @change="updateeventNotify(scope.row.messageFunction[index],scope.row.id[index],'isPlatform')"></el-switch>
                                    </span>
                                 </div>
                              </template>
                           </el-table-column>
                           <el-table-column prop="integerinvitedNum" key="7"  label="后台消息接收人" min-width="250" >
                              <template slot-scope="scope" >
                                 <div class="thisrule"  >
                                    <span v-for="(item,index) in scope.row.backstagerecipient" :key="index">
                                    <label v-for="(items,i) in item" :key="i"> {{items.userName || '无'}}</label>
                                       <label class="magbtn" @click="managerecipient(scope.row.backstagerecipient[index],scope.row.id[index],'PLATFORM',scope.row.code[index])">管 理</label>
                                    </span>
                                 </div>
                              </template>
                           </el-table-column>

                        </template>

                     </el-table>
                  </el-row>
               </el-tab-pane>
            </el-tabs>
         </div>
         <el-dialog
               :title="detailstype"
               :visible.sync="previewdialogVisible"
               width="30%"
         >
            <div style="text-align: center">
               <template v-if="detailstype== '订房确认提醒' ">
                  <img src="../../../assets/ordereceiving.png" />
               </template>
               <template v-if="detailstype== '订房失败提醒' ">
                  <img src="../../../assets/Fromasingle.png" />
               </template>
               <template v-if="detailstype== '酒店入住提醒'  ">
                  <img src="../../../assets/checkin.png" />
               </template>
               <template v-if="detailstype== '退款失败提醒'  ">
                  <img src="../../../assets/Refundfailure.png" />
               </template>
               <template v-if="detailstype== '退款成功提醒'  ">
                  <img src="../../../assets/Refundsuccess.png" />
               </template>
               <template v-if="detailstype== '优惠券领取成功时' ">
                  <img src="../../../assets/Getthesuccess.png" />
               </template>
               <template v-if="detailstype== '预售券购买成功时' ">
                  <img src="../../../assets/Bookingvouchers.png" />
               </template>
               <template v-if="detailstype== '优惠券过期前3天提醒' || detailstype== '预售券过期前3天提醒' ">
                  <img src="../../../assets/pastdue.png" />
               </template>
            </div>
            <span slot="footer" class="dialog-footer">
             <el-button @click="previewdialogVisible = false">取 消</el-button>
             <el-button type="primary" @click="previewdialogVisible = false">确 定</el-button>
           </span>
         </el-dialog>
         <!--商户预览弹窗-->
         <el-dialog
               :title="detailstype"
               :visible.sync="detailsdialogVisible"
               width="30%"
               >
            <div class="span-box">
               <template v-if="detailstype== '预订客房订单提交成功' ">
                <h4>酒店订房新单通知</h4>
               <p>预订时间：2013年11月11日19分</p>
               <p>房间数：2间大床房</p>
               <p>入离日期：2013年11月11日-2013年11月13日</p>
               <p>入住人：张三</p>
               <p>房价：400 预付</p>
               <p>订单号：1221212121212</p>
               <p>到店时间：17：00</p>
               <p>为了客人入住，请尽快确认，谢谢</p>
               <p>模板ID：1GpgyZvwC_YthkO49_2N4PlppbMR-93oOusjcqlaoTY</p>
               <p>所属行业：酒店服务</p>
               </template>
               <template v-if="detailstype== '预订客房酒店确认状态通知' ">
                  <h4>客户预定订房已确定。</h4>
                  <p>订单编号：840146920146993152</p>
                  <p>入住时间：20170508 - 20170513</p>
                  <p>房间类型：大床房</p>
                  <p>房间数量：2</p>
                  <p>模板ID：Zw8ylSFVDYS9SL-QQ3XqPpRJqjYUlI9W770xYjkIA4E</p>
                  <p>所属行业：酒店服务</p>
               </template>
               <template v-if="detailstype== '送至客房商品支付成功' || detailstype == '需邮寄商品支付成功' || detailstype == '自提点自取商品支付成功'  ">
                  <h4>会员王小艾已成功下单。</h4>
                  <p>订单编号：123141424</p>
                  <p>会员姓名：王小艾</p>
                  <p>商品名称：红酒</p>
                  <p>总金额：198</p>
                  <p>配送方式：送至101客房</p>
                  <p>请尽快处理！</p>
                  <p>模板ID：KPyyb9HPRRNk-2lkRpnyXKRiQgLNIa0w63ACVtu66m4</p>
                  <p>所属行业：公寓/民宿</p>
               </template>
               <template v-if="detailstype== '预售券购买订单支付成功' ">
                  <h4>会员王小艾已成功下单。</h4>
                  <p>订单编号：123141424</p>
                  <p>会员姓名：王小艾</p>
                  <p>商品名称：商品名称：XX优惠券</p>
                  <p>总金额：198</p>
                  <p>配送方式：卡包查看</p>
                  <p>请尽快处理！</p>
                  <p>模板ID：KPyyb9HPRRNk-2lkRpnyXKRiQgLNIa0w63ACVtu66m4</p>
                  <p>所属行业：公寓/民宿</p>
               </template>
            </div>


            <span slot="footer" class="dialog-footer">
             <el-button @click="detailsdialogVisible = false">取 消</el-button>
             <el-button type="primary" @click="detailsdialogVisible = false">确 定</el-button>
           </span>
         </el-dialog>



          <!--管理弹窗-->
         <el-dialog
               title="接收人管理"
               :visible.sync="OfficialdialogVisible"
               width="50%"
               >
            <el-form style="width: 80%;margin: 0 auto" v-if="this.types == 'PUBLICACCOUNT' ">
              <el-form-item label="用户管理" label-width="100">
                    <el-select
                          v-model="memberlistIds"
                          multiple
                          filterable
                          remote
                          class="width-500"
                          value-key="openId"
                          placeholder="请输入关键词"
                          :remote-method="remoteMethod"
                          :loading="loading">
                       <el-option
                             class="width-500"
                             v-for="item in options"
                             :key="item.label"
                             @click.native="geiitem"
                             :label="item.label"
                             :openId="item.openId"
                             :value="item.value">
                       </el-option>
                    </el-select>


              </el-form-item>
            </el-form>
            <template v-else>
               <el-row class="content-flex-box">
                  <el-col :span="6" class="left-table-bg">
                     <el-row class="left-table">
                        <el-table
                              ref="sideTable"
                              :data="sideData"
                              border fit
                              highlight-current-row
                              row-key="id"
                              style="width: 100%;"
                              @row-click="(row) => handleCurrentChange(row)"
                              :stripe="true"
                              size="medium">
                           <el-table-column prop="name" :label="$t('msg.org_list')"></el-table-column>
                        </el-table>
                     </el-row>
                  </el-col>
                  <el-col :span="18" class="right-table-bg">
                     <el-row class="right-table">

                        <el-row class="table-box">
                           <el-table
                                 ref="mainTable"
                                 v-loading="tableLoad"
                                 :data="userList"
                                 highlight-current-row
                                 style="width: 100%;"
                                 height="420"
                           >
                              <el-table-column prop="number" label="用户名" >
                                 <template slot-scope="scope">
                                    {{scope.row.nickName}}
                                 </template>
                              </el-table-column>

                              <el-table-column :label="$t('msg.operating')"  width="110">
                                 <template slot-scope="scope">
                                    <el-button type="text" @click.stop="handleEdit(scope.row)">
                                       选择
                                    </el-button>

                                 </template>
                              </el-table-column>
                           </el-table>
                        </el-row>
                     </el-row>
                  </el-col>
               </el-row>
               <p style="margin-left: 30px">接收人：
                  <el-tag
                        v-for="tag in memberlistIds"
                        :key="tag.userId"
                        closable
                        @close="deleterowtag(tag,index)"
                  >{{tag.nickName}} </el-tag>
               </p>
            </template>

            <span slot="footer" class="dialog-footer">
             <el-button @click="OfficialdialogVisible = false">取 消</el-button>
             <el-button type="primary" @click="changemeber">确 定</el-button>
           </span>
         </el-dialog>

      </el-row>


   </div>
</template>

<script>
import { getDict } from '@/common/js/common'
import { mapState } from "vuex";
import {urlObj} from "@/api/interface";
import { system } from '@/api/interface/system'
export default {
   data() {
      return {
         that: this,
         pageshow: true,
         activeName: 'CLIENT',   // tab栏
         tabslist: [//tab列表
            {name: 'CLIENT', label: '客户通知'},
            {name: 'COMMERCIAL_TENANT', label: '商户通知'},
         ],
         tableData:[],
         memberlist:[],
         memberlistIds:[],
         rowlist:[],
         userList:[],
         notifyType:'',
         detailstype:'',
         tableLoad:false,
         detailsdialogVisible:false,
         OfficialdialogVisible:false,
         backstagedialogVisible:false,
         previewdialogVisible:false,
         applettempletNum:0,
         OfficiallettempletNum:0,
         searchKey:'',
         param:'',
         type:'',
         code:'',
         receiver:'',
         page:1,
         clientelelist:[1,2,3,4],
         commerciallist:[1,2,3],
         clienttableData:[//表格数据
            {
               messageType:'订单消息',
               thisRule:['预订客房酒店确认状态通知','预订客房酒店拒绝状态通知','入住前一天提醒'],
               templateTitle:['订房确认提醒','订房失败提醒','酒店入住提醒'],
               templateMessage:['--','--','--'],
               openingFunction:[false,false,false],
               id:[],
               code:[]
            },
            {
               messageType:'维权消息',
               thisRule:[' 退款失败','退款成功'],
               templateTitle:['退款失败提醒','退款成功提醒'],
               templateMessage:['--','--'],
               openingFunction:[false,false],
               id:[],
               code:[]
            },
            {
               messageType:'优惠券消息',
               thisRule:['优惠券领取成功提醒','优惠券即将过期提醒'],
               templateTitle:['优惠券领取成功时','优惠券过期前3天提醒'],
               templateMessage:['--','--'],
               openingFunction:[false,false],
               id:[],
               code:[]
            },
            {
               messageType:'预售券消息',
               thisRule:[' 预售券购买成功提醒','预售券即将过期提醒'],
               templateTitle:['预售券购买成功时','预售券过期前3天提醒'],
               templateMessage:['--','--'],
               openingFunction:[false,false],
               id:[],
               code:[]
            },

         ],
         commercialtableData:[//商户
            {
               messageType:'酒店预订消息',
               thisRule:['预订客房订单提交成功','预订客房酒店确认状态通知'],
               templateTitle:['订房提交成功','订房确认提醒'],
               recipient:['张','张'],
               officialtemplateMessage:['NO','NO'],
               messageFunction:['NO','NO'],
               backstagerecipient:['张','李'],
               id:[],
               code:[]
            },
            {
               messageType:'商城订单消息',
               thisRule:['送至客房商品支付成功','自提点自取商品支付成功','需邮寄商品支付成功'],
               templateTitle:['商品待发货提醒','商品待发货提醒','商品待发货提醒'],
               recipient:['张','爱','张'],
               officialtemplateMessage:['NO','NO','NO'],
               messageFunction:['NO','NO','NO'],
               backstagerecipient:['李','王','赵'],
               id:[],
               code:[]
            },
            {
               messageType:'预售券订单消息',
               thisRule:['预售券购买订单支付成功'],
               templateTitle:['预售券购买支付成功'],
               recipient:['张'],
               officialtemplateMessage:['NO'],
               messageFunction:['NO'],
               backstagerecipient:['张'],
               id:[],
               code:[]
            },

         ],

        options: [],
        value: [],
        list: [],
         sideData:[],
         types:'',
         index:0,
         openIdlist:[],
        loading: false,



      }
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.hotelCode = this.hotelInfo.hotelCode
               this.getEventNotify()
               this.getMember()
            }
         }
      }
   },
   components: {
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   mounted() {

      this.hotelId = this.hotelInfo.id
      getDict(['order-source', 'order-status'])
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.tableData = this.clienttableData
      this.getEventNotify()
      this.getMember()
      this.getOrg()
      this.getUser()
   },

   methods: {
      //获取选中的openId和userId
      geiitem(){
         if (this.types == 'PUBLICACCOUNT'){
            this.openIdlist = []
            this.memberlistIds.forEach(item=>{
               this.memberlist.forEach(items=>{
                  if (item == items.memberId){
                     this.openIdlist.push({openId:items.openId,userId:items.memberId})
                  }
               })
            })
         }
      },
      //删除标签
      deleterowtag(val){
         this.memberlistIds = this.memberlistIds.filter(item=>{
            return item.userId != val.userId
         })
      },
      // 选择用户
      handleEdit(row){
         let arr = []
         arr =  this.memberlistIds.filter(item=>{
           return item.userId == row.id
         })
         if (arr.length == 0){
               this.memberlistIds.push({userId:row.id,nickName:row.nickName})
         }else{
            this.$message.error('你已添加了该用户')
         }
      },
      //获取通知信息
      getEventNotify(){
         if (this.activeName == 'CLIENT'){
            this.notifyType = 'CLIENT'
         }else{
            this.notifyType =  'COMMERCIAL_TENANT'
         }
         const url = urlObj.getEventNotify+ `?limit=${this.limit}&page=${this.page}`
         let param = {
            hotelId:this.hotelInfo.id,
            notifyType:this.notifyType
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.applettempletNum = 0
               this.OfficiallettempletNum = 0

               if (res.records.length != 0){
                  if (this.activeName == 'CLIENT'){

                     this.clientelelist.forEach((i,serial)=>{
                        this.clienttableData[serial].openingFunction.forEach((item,index)=>{
                           if (serial == 0){
                              this.clienttableData[serial].openingFunction[index] = []
                              this.clienttableData[serial].openingFunction[index] = res.records[index].isApplet
                              this.clienttableData[serial].id[index] = res.records[index].id
                              this.clienttableData[serial].code[index] = res.records[index].code


                           }
                           if (serial == 1){
                              this.clienttableData[serial].openingFunction[index] = []
                              this.clienttableData[serial].openingFunction[index] = res.records[index+3].isApplet
                              this.clienttableData[serial].id[index] = res.records[index + 3].id
                              this.clienttableData[serial].code[index] = res.records[index + 3].code

                           }
                           if (serial == 2){
                              this.clienttableData[serial].openingFunction[index] = []
                              this.clienttableData[serial].openingFunction[index] = res.records[index+5].isApplet
                              this.clienttableData[serial].id[index] = res.records[index + 5].id
                              this.clienttableData[serial].code[index] = res.records[index + 5].code

                           }
                           if (serial == 3){
                              this.clienttableData[serial].openingFunction[index] = []
                              this.clienttableData[serial].openingFunction[index] = res.records[index +7].isApplet
                              this.clienttableData[serial].id[index] = res.records[index +7].id
                              this.clienttableData[serial].code[index] = res.records[index + 7].code

                           }

                        })
                     })
                     if (this.clienttableData[0].openingFunction[0] == 'YES' ||this.clienttableData[0].openingFunction[1] == 'YES'){
                        this.applettempletNum++
                     }
                     if (this.clienttableData[0].openingFunction[2] == 'YES'){
                        this.applettempletNum++
                     }
                     if (this.clienttableData[1].openingFunction[0] == 'YES' || this.clienttableData[1].openingFunction[1] == 'YES'){
                        this.applettempletNum++
                     }
                     if (this.clienttableData[2].openingFunction[0] == 'YES'){
                        this.applettempletNum++
                     }
                     if (this.clienttableData[3].openingFunction[1] == 'YES'){
                        this.applettempletNum++
                     }
                     this.tableData = []
                     this.$nextTick(()=>{
                        this.tableData = this.clienttableData
                     })
                  }else{
                     this.commerciallist.forEach((i,serial)=>{
                        this.commercialtableData[serial].officialtemplateMessage.forEach((item,index)=>{
                           if (serial == 0){
                              this.commercialtableData[serial].recipient[index] = []
                              this.commercialtableData[serial].backstagerecipient[index] = []
                              this.commercialtableData[serial].officialtemplateMessage[index] =  res.records[index].isOfficial
                              this.commercialtableData[serial].messageFunction[index] = res.records[index].isPlatform
                              this.commercialtableData[serial].code[index] = res.records[index].code
                              this.commercialtableData[serial].recipient[index] = res.records[index].notifyUserList.filter(items=>{
                                 return items.type == 'PUBLICACCOUNT'
                              })
                              this.commercialtableData[serial].backstagerecipient[index] = res.records[index].notifyUserList.filter(items=>{
                                 return items.type == 'PLATFORM'
                              })
                              this.commercialtableData[serial].id[index] = res.records[index].id
                           }
                           if (serial == 1){
                              this.commercialtableData[serial].recipient[index] = []
                              this.commercialtableData[serial].backstagerecipient[index] = []
                              this.commercialtableData[serial].officialtemplateMessage[index] =  res.records[index + 2].isOfficial
                              this.commercialtableData[serial].messageFunction[index] = res.records[index + 2].isPlatform
                              this.commercialtableData[serial].code[index] = res.records[index + 2].code
                              this.commercialtableData[serial].recipient[index] = res.records[index + 2].notifyUserList.filter(items=>{
                                 return items.type == 'PUBLICACCOUNT'
                              })
                              this.commercialtableData[serial].backstagerecipient[index] = res.records[index + 2].notifyUserList.filter(items=>{
                                 return items.type == 'PLATFORM'
                              })
                              this.commercialtableData[serial].id[index] = res.records[index + 2].id
                           }
                           if (serial == 2){
                              this.commercialtableData[serial].recipient[index] = []
                              this.commercialtableData[serial].backstagerecipient[index] = []
                              this.commercialtableData[serial].officialtemplateMessage[index] =  res.records[index + 5].isOfficial
                              this.commercialtableData[serial].messageFunction[index] = res.records[index + 5].isPlatform
                              this.commercialtableData[serial].code[index] = res.records[index + 5].code
                              this.commercialtableData[serial].id[index] = res.records[index + 5].id

                              this.commercialtableData[serial].recipient[index] = res.records[index + 5].notifyUserList.filter(items=>{
                                 return items.type == 'PUBLICACCOUNT'
                              })
                              this.commercialtableData[serial].backstagerecipient[index] = res.records[index + 5].notifyUserList.filter(items=>{
                                 return items.type == 'PLATFORM'
                              })
                           }
                           if (this.commercialtableData[serial].recipient[index].length == 0){
                              this.commercialtableData[serial].recipient[index].push({userName:''})
                           }
                           if (this.commercialtableData[serial].backstagerecipient[index].length == 0){
                              this.commercialtableData[serial].backstagerecipient[index].push({userName:''})
                           }
                        })

                     })
                     if (this.commercialtableData[0].officialtemplateMessage[0] == 'YES'){
                        this.OfficiallettempletNum++
                     }
                     if (this.commercialtableData[0].officialtemplateMessage[1] == 'YES'){
                        this.OfficiallettempletNum++
                     }
                     if (this.commercialtableData[1].officialtemplateMessage[0] == 'YES' || this.commercialtableData[1].officialtemplateMessage[1] == 'YES' ||this.commercialtableData[1].officialtemplateMessage[2] == 'YES' || this.commercialtableData[2].officialtemplateMessage[0] == 'YES'){
                        this.OfficiallettempletNum++
                     }
                     this.tableData = []
                     this.$nextTick(()=>{
                        this.tableData = this.commercialtableData
                     })
                  }
               }else{
                  if (this.activeName == 'CLIENT'){

                     this.tableData = []
                     this.$nextTick(()=>{
                        this.tableData = this.clienttableData
                     })
                  }else{
                     this.tableData = []
                     this.$nextTick(()=>{
                        this.tableData = this.commercialtableData
                     })

                  }
               }


            }
         })
      },
      //修改通知活动配置
      updateeventNotify(isApplet,id,type){
         const url = urlObj.updateeventNotify
         if (type == 'isApplet'){
            this.param = {
               id:id,
               isApplet:isApplet,
            }
         }
         if (type == 'isPlatform'){
            this.param = {
               id:id,
               isPlatform:isApplet,
            }
         }
         if (type == 'isOfficial'){
            this.param = {
               id:id,
               isOfficial:isApplet,
            }
         }
         this.$axios.post(url, this.param).then(res => {
            this.$message.success('修改成功')
            this.getEventNotify()
         })
      },
      //下拉框数据
      remoteMethod(query) {
         if (query !== '') {
            this.loading = true;
            setTimeout(() => {
               this.loading = false;
               this.options = this.list.filter(item => {
                  return item.label.toLowerCase()
                        .indexOf(query.toLowerCase()) > -1;
               });
            }, 200);
         } else {
            this.options = this.list;
         }
      },

      // 获取会员列表
      getMember(){
         const param = {
            source: "WECHAT_OFFICIAL",
            hotelId: this.hotelInfo.id
         }
         const url = urlObj.getPlatformList
         this.$axios.post(url, param, 'json').then((res) => {
            if(res.success){
               this.total = res.total
               this.memberlist = res.records
               this.memberlist = this.memberlist.filter(item=>{
                  return item.hasOwnProperty('nickName')
               })
               this.memberlist.forEach((item,index)=>{
                  this.list.push({label:item.nickName,openId:item.openId,value:item.memberId})
               })
               // this.options = this.memberlist.map(item => {
               //    return { value: item.memberId, label: item.nickName,openId:item.openId };
               // });
            }
         })
      },
      // 获取用户列表
      getUser(){
         const param = {
            orgId: this.orgId,
            companyId: this.companyId
         }
         const url = system.userList + `?limit=9999&page=1`
         this.$axios.post(url, param, 'json').then((res) => {
            if(res.success){
               this.tableLoad = false
               this.total = res.total
               let userList = res.records
               userList.forEach(item => {
                  item.headPortrait && (item.headPortrait = window.getResourcesHost() + item.headPortrait)
               })
               this.userList = userList
            }
         })
      },
      // 侧边列表当前选中
      handleCurrentChange: (() => {
         let id
         return function (row) {
            if (row.id !== id){
               id = row.id
               this.orgId = row.id
               this.companyId = row.companyId
               // 用于动态显示弹窗文本
               this.orgName = row.name
               this.orgIsShow = true
               // 查询切换
               //this.orgId = val.id
            } else {
               // 取消选中 清空属性
               this.cid = ''
               this.orgId = ''
               this.companyId = ''
               this.orgIsShow = false
               this.orgName = ''
               this.$refs.sideTable.setCurrentRow();
            }
            this.page = 1
            this.getUser()
         }
      })(),
      // 获取组织列表
      getOrg(){
         const param = { companyId: this.companyId }
         const url = system.org
         this.$axios.post(url, param, 'json').then(res => {
            if(res.success){
               this.sideLoad = false
               this.sideData = res.children
            }
         })
      },
      //查看
      lookat(type){
         this.detailstype = type
         if(this.activeName == 'CLIENT'){
            this.previewdialogVisible = true
         }else{
            this.detailsdialogVisible = true
         }
      },

      //修改接收人
      changemeber(){
         if(this.types == 'PLATFORM'){
            this.receiver.newUserList = this.memberlistIds
            let arr = []

            this.memberlistIds.forEach(item=>{
               arr.push(item.userId)
            })
            this.rowlist.forEach(i=>{
               if (arr.indexOf(i.userId) == -1){
                  this.receiver.removeUserIds.push(i.userId)
               }
            })
         }else{
            this.receiver.newUserList = this.openIdlist
            let arr = []
            this.memberlistIds.forEach(item=>{
               arr.push(item)
            })
            this.rowlist.forEach(i=>{
               if (arr.indexOf(i.userId) == -1){
                  this.receiver.removeUserIds.push(i.userId)
               }
            })

         }

         const url = urlObj.setReceiver
         this.$axios.post(url, this.receiver, 'json').then((res) => {
            if(res.success){
               this.OfficialdialogVisible = false
               this.$message.success('修改成功')
               this.getEventNotify()
            }
         })
      },

      //回显会员
     managerecipient(i,ids,types,code){
         this.rowlist = i
         this.types = types
         this.memberlistIds = []
         if (types == 'PLATFORM'){
            this.options = this.userList.map(item => {
               return { value: item.id, label: item.nickName };
            });
            i.forEach(item=>{
               if (item.hasOwnProperty('userId')){
                  this.memberlistIds.push({userId:item.userId,nickName:item.userName})
               }
            })
         }else{
            i.forEach(item=>{
               if (item.hasOwnProperty('userId')){
                  this.memberlistIds.push(item.userId)
               }
            })
            this.options = this.memberlist.map(item => {
               return { value: item.memberId,openId:item.openId, label: item.nickName };
            });

         }
        this.receiver = {
           hotelId:this.hotelInfo.id,
           type:types,
           notifyId:ids,
           newUserList:this.memberlistIds,
           removeUserIds: [],
           code: code
        }
       this.OfficialdialogVisible = true
     },

   },

}
</script>

<style lang="scss" scoped>
.cont {
   .search-box {
      display: flex;

      .el-row {

      }

      display: flex;

      .search-row {
         .search-item {
            margin: 0 100px 0 0;
         }

         .group-box {
            ::v-deep .el-radio-button__inner {
               background: transparent;
            }
         }
      }
   }
   .table-box {
      .add-btn {
         margin-bottom: 10px;
         float: right
      }
      .add-btn {
         margin-bottom: 30px;
         float: right;
         width: 98px
      }
      .member-info {
         display: flex;
         img {
            width: 60px;
            margin-right: 10px
         }
      }
      .right-tabbox-newnotice {
         margin-top: 40px;

      }

   }
   .des {
      color: red;
      margin-right: 5px
   }
   .mg15 {
      margin: 0 15px;
      width: 98px;
   }
   .mg72 {
      margin-left: 72px;
   }
}
.statistics-box{
   display: flex;
}
.statistics-box > div{
   margin: 20px 30px;
   text-align: center;
}
/deep/ .el-table__row {
  padding: 0;
}
/deep/ .el-table__row > td{
   padding: 0;
}
/deep/ .cell {
   padding: 0;
}
.thisrule span{
   display: inherit;
   border-bottom: 1px solid #ededed;
   padding: 10px;
}
.thisrule p{
   border-bottom: 1px solid #ededed;
   padding: 2.5px 10px;
   text-align: left;
}
.thisrule :last-child{
   border: none;
}
.tips-box{
   margin: 30px;
   font-size: 13px;
}
.magbtn{
   float: right;
   padding: 0 10px;
   background: #409eff;
   color: #fff;
   border-radius: 3px;
   cursor:pointer;
}
.span-box p{
   padding: 5px;
   font-size: 16px;
   color: #2D2D2D;
}
</style>
